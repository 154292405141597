.title {
    width: fit-content;
    color: #fff;
    font-weight: 700;
    border-bottom: 1px solid var(--color-txt-three);
}

.text-container {
    p {
        font-size: .9rem;
    }
}

.section-video-left {    
    background: var(--color-background-gradient-secondary);    
    .video {
        width: 30rem;
        height: 18rem;
        display: block;  
        align-self:center; 
    }
}

.section-video-right {
    background: var(--color-background-gradient-secondary-two);    
    .video {
        width: 30rem;
        height: 18rem;        
        display: block;  
        align-self:center; 
    }
}