.header {
    border-bottom: 41px solid var(--color-background-secondary);

    .row {padding: 0; margin: 0;}
    &-banner {
        justify-content: center;
        
        img {
            padding: 0 1rem;
        }
    }
    
    &-logo h2 {
        font-size: 50px;
        font-weight: 100;
    }     
    &-logo small {
        font-size: 17px;
        font-weight: 100;
        letter-spacing: .70rem;
    }  
    
    &-logo {
        align-content: center;
        display: grid;
    }
    
}