@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');

@import 'bootstrap/dist/css/bootstrap.min.css';

:root {
    --color-background: #0a0615;
    --color-background-secondary: #8b5cf6;
    
    --color-background-gradient: linear-gradient(to right, #ff0048, #5f00cc);
    --color-background-gradient-secondary: linear-gradient(to right, #8b5cf6, #0a0615);
    --color-background-gradient-secondary-two: linear-gradient(to right, #0a0615, #8b5cf6);
    
    --color-background-transparent: #564585d2;

    /*fonts color*/
    --color-txt-default: #ffff;
    --color-txt-one: #0a0615;
    --color-txt-two: #8b5cf6;
    --color-txt-three: #ff0048;
    --color-txt-four: #b094ff;

    /*hover*/
    --color-hover-one: #1d0044;
    --color-hover-two: #b094ff;
 
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}