.skills {
    text-align: center;
    
    h2 {
        width: 6rem;

        font-size: 2rem;
        font-weight: 100;

        color: #fff;
        
        margin: 2rem auto;
        border-bottom: 1px solid var(--color-txt-three);
    }

    &-card {
        background: var(--color-background-gradient);

        border-radius: 20px;

        h3 {
            font-weight: 700;
            font-size: 1.5rem;
            
            color: #fff;
        }

        ul {
            min-height: 35vh;
            text-align: center;

            color: #fff;
            
            margin-bottom: 0;
            padding: 0 0 1rem 0;
            display: grid;
            align-content: center;

            li {
                font-size: 1.2rem;
                font-weight: 100;
                list-style: none;

                padding: 0 0 1rem;
                line-height: 15px;

                img {
                    width: 20px;
                    margin: 0px 0 4px;
                }

                small {
                    color: var(--color-hover-two);
                }
            }
        }
    }
    
}