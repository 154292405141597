.header-web {
    width: 100%;
    background-image: url(../../assets/_imgWebProjects/_Banner-web-projects.png);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.projects {
    color: #ffff;

    img {
        width: 20rem;
    }
    
    .card-container {
        align-self: center;
        border: 1px solid var(--color-background-secondary);
        border-radius: 2rem;
        overflow-x: hidden;

        .card-title {
            font-size: 1.5rem;
            font-weight: 700;
            margin-top: .7rem;
        }
        .card-text {
            font-size: .94rem;
        }
        span {
            margin: .8rem 0;           
            font-weight: 700;
        }   
    }
}
