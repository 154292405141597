.footer {
    
    background: var(--color-background-secondary);
    text-align: center;

    padding: 2rem 0;
    h2 {
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 2px;
        
        color: #fff1ffad;
        
    }
}